export enum EUserType {
    USER = 'USER',
    COMPANY_ADMIN = 'COMPANY_ADMIN',
    SUPER_ADMIN = 'SUPER_ADMIN'
}

export enum ETenantType {
    TENANT = 'TENANT',
    SUPER_TENANT = 'SUPER_TENANT',
    SUB_TENANT = 'SUB_TENANT'
}

export enum EUserStatus {
    REGISTERED = 'REGISTERED'
}